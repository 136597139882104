.auth_page
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	min-height: 100dvh
	width: 100vw
	padding-top: 10vh
	.auth_container
		width: 100%
		flex: 1
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		gap: 1rem
		.auth_info
			margin-bottom: 2rem
		.auth_title
			font-size: 2.5rem
			font-weight: 600
			color: $text_color_green
			margin-bottom: 1rem
			text-align: center
		.auth_description
			font-size: 1.2rem
			font-weight: 400
			color: $text_color_gray
			text-align: center
			span
				color: $text_color_green
		.auth_form
			width: 100%
			max-width: 40vw
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
			gap: 2rem
			.auth_form_group
				width: 100%
				display: flex
				flex-direction: column
				justify-content: center
				align-items: flex-start
				gap: 0.5rem
				label
					font-size: 1.2rem
					font-weight: 400
					color: $text_color_white
					margin-bottom: 0.5rem
				.password_input
					width: 100%
					display: flex
					gap: 1rem
					align-items: center
				.eye_container
					padding: 0 1rem
				input
					width: 100%
					padding: 1.5rem 2rem
					border: 2px solid rgba(119, 237, 145, .2)
					border-radius: 10rem
					font-size: 1rem
					font-weight: 500
					background-color: transparent
					transition: all 0.2s ease-in-out
					&:focus
						outline: none
						border: 2px solid rgba(119, 237, 145, 1)
						background-color: transparent
					&::selection
						background-color: rgba(119, 237, 145, .2)
					&::placeholder
						color: rgba(119, 237, 145, .4)
					&:autofill::selection
						background-color: transparent !important
				.error
					color: $text_color_error
			button
				margin-top: 1rem
				width: 60%
				padding: 1.5rem 2rem
				border: none
				border-radius: 10rem
				font-size: 1rem
				font-weight: 500
				background-color: $text_color_green
				color: $text_color_black
				cursor: pointer
				transition: all 0.2s ease-in-out
				&:hover
					background-color: $background_color_white
					color: $text_color_black
					scale: 1.1
				&:active
					outline: none
					color: $text_color_black
					scale: .9
