.dashboard_sidebar_container
	width: 20%
	height: 100vh
	background-color: $background_color_secondary
	display: flex
	flex-direction: column
	align-items: center
	justify-content: space-between
	padding-top: 2rem
	position: fixed
	left: 0
	padding: 2rem 0
	.top, .bottom
		width: 100%
		padding: 2rem
	.logo_section
		margin-bottom: 4rem
		display: flex
		align-items: center
		justify-content: space-between
		.logo
			height: 2.4rem
	.dashboard_list_item
		color: $text_color_white
		width: 100%
		display: flex
		align-items: center
		justify-content: flex-start
		padding: .5rem
		border-radius: 4rem
		margin-bottom: 1rem
		cursor: pointer
		border: 3px solid transparent
		transition: all 0.2s ease-in-out
		gap: 1rem
		*
			cursor: pointer
			transition: all 0.2s ease-in-out
		.dashboard_list_item_icon
			display: inline-block
			// width: 4rem
			// height: 4rem
			padding: 1rem
			display: flex
			align-items: center
			justify-content: center
			border-radius: 50%
			.dashboard_list_item_icon_src
				width: 1.5rem
				height: auto
		.dashboard_list_item_title
			font-size: 1.2rem
			font-weight: 300
			color: $text_color_white
		&:hover
			background-color: $background_color_tertiary
		&.selected
			color: $text_color_black
			border: 3px solid $text_color_green
			background-color: $background_color_white
			.dashboard_list_item_icon
				background-color: $background_color_primary
			.dashboard_list_item_text
				color: $text_color_green
			.dashboard_list_item_icon
				font-size: 1.5rem
				margin-right: 1rem
				color: $text_color_white
			.dashboard_list_item_title
				font-weight: 500
				font-size: 1.2rem
				color: $text_color_black
				&.active
					color: $text_color_green