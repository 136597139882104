.dashboard_container
	width: 100vw
	min-height: 100vh
	display: flex
	align-items: flex-start
	justify-content: start
	background-color: $background_color_primary
	padding-left: 20%
	overflow: hidden
	.section_wrapper
		width: 50%
		display: flex
		flex-direction: column-reverse
		gap: 2rem
	.users_header
		width: 100%
		padding: 1rem
		display: flex
		align-items: center
		justify-content: space-between
		.users_header_title
			font-size: 2rem
			font-weight: 500
			color: $text_color_green
		.header_cta
			display: flex
			align-items: center
			gap: 1rem
			.users_header_button
				padding: .6rem 2rem
				border-radius: 2rem
				background-color: transparent
				border: 2px solid $text_color_green
				color: $text_color_green
				font-size: .8rem
				font-weight: 400
				cursor: pointer
				transition: all .2s ease-in-out
				&:hover
					background-color: $text_color_green
					color: $background_color_tertiary
					scale: 1.1
				&:active
					background-color: $background_color_tertiary
					scale: .9
	.item_wrapper
		display: flex
		align-items: center
		justify-content: center
		gap: 1rem
	.users_list
		background-color: $background_color_secondary
		padding: 2rem
		border-radius: 2rem
		.users_list_header
			width: 100%
			padding: 1rem
			display: grid
			grid-template-columns: repeat(5, 1fr)
			background-color: $background_color_tertiary
			border-radius: 1rem 1rem 0 0
		.users_list_body
			width: 100%
			border: 2px solid $background_color_tertiary
			border-radius: 0 0 1rem 1rem
			.users_list_item
				padding: 1rem
				display: grid
				grid-template-columns: repeat(5, 1fr)
				.users_list_item_text
					font-size: .8rem
					font-weight: 400
				.users_list_item_button
					display: flex
					align-items: center
					justify-content: center
					background-color: $text_color_green
					border: none
					border-radius: 2rem
					padding: .6rem 1.6rem
					cursor: pointer
					transition: all .2s ease-in-out
					color: $background_color_tertiary
					&:hover
						background-color: $background_color_light
						scale: 1.1
					&:active
						background-color: $background_color_tertiary
						scale: .9

.edit_modal
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	background-color: rgba(0, 0, 0, .5)
	display: flex
	align-items: center
	justify-content: center
	backdrop-filter: blur(5px)
	cursor: pointer
	z-index: 100
.edit_modal_form
	background-color: $background_color_secondary
	width: 50vw
	border-radius: 2rem
	padding: 2rem
	display: flex
	flex-direction: column
	align-items: center
	gap: 2rem
	cursor: default
.edit_modal_form_item
	display: flex
	flex-direction: column
	align-items: center
	justify-content: space-evenly
	width: 100%
	height: 100%
	gap: 1rem
.edit_modal_form_label
	color: $text_color_green
	font-size: .8rem
	font-weight: 400
	width: 100%
	height: 100%
.edit_modal_form_input
	padding: 1.2rem 2rem
	width: 100%
	height: 100%
	border: none
	border: 2px solid $background_color_tertiary
	background-color: transparent
	color: $text_color_white
	font-size: 1rem
	font-weight: 400
	border-radius: 2rem
	transition: all .2s ease-in-out
	&:focus
		outline: none
		border: 2px solid $text_color_green
.edit_modal_form_button
	display: flex
	align-items: center
	justify-content: center
	background-color: $text_color_green
	border: none
	border-radius: 2rem
	padding: .6rem 1.6rem
	cursor: pointer
	transition: all .2s ease-in-out
	color: $background_color_tertiary
	&:hover
		background-color: $background_color_light
		scale: 1.1
	&:active
		background-color: $background_color_tertiary
		scale: .9

.row-full-center
	display: flex
	flex-direction: row
	align-items: center
	justify-content: space-between
	width: 100%
.transactions_list_wrapper
	width: 100%
	min-height: 50vh
	display: flex
	flex-direction: column
	.transactions_header
		width: 100%
		padding: 1rem
		display: flex
		align-items: center
		justify-content: space-between
		.transactions_header_title
			font-size: 2rem
			font-weight: 500
			color: $text_color_green
		.cta
			display: flex
			gap: 1rem
			align-items: centers
		.transactions_header_button
			padding: .6rem 2rem
			border-radius: 2rem
			background-color: transparent
			border: 2px solid $text_color_green
			color: $text_color_green
			font-size: .8rem
			font-weight: 400
			cursor: pointer
			transition: all .2s ease-in-out
			&:hover
				background-color: $text_color_green
				color: $background_color_tertiary
				scale: 1.1
			&:active
				background-color: $background_color_tertiary
				scale: .9
		.selected
			background-color: $text_color_green
			color: $background_color_tertiary
			&:hover
				background-color: $text_color_green
				color: $background_color_tertiary
				scale: 1
			&:active
				background-color: $text_color_green
				color: $background_color_tertiary
				scale: 1
	.transaction_list_container
		width: 100%
		.transaction_list_body
			width: 100%
			display: flex
			flex-direction: column
			gap: 2rem
			.body
				width: 100%
				border: 2px solid $background_color_tertiary
				border-radius: 0 0 1rem 1rem
			.transaction_list
				width: 100%
				padding: 2rem
				background-color: $background_color_secondary
				border-radius: 2rem
				.transaction_list_title
					font-size: 1.2rem
					font-weight: 500
					color: $text_color_green
					margin-bottom: 1rem
				.transaction_list_header
					width: 100%
					padding: 1rem
					display: grid
					grid-template-columns: repeat(4, 1fr)
					background-color: $background_color_tertiary
					border-radius: 1rem 1rem 0 0
					.item_wrapper
						display: flex
						align-items: center
						justify-content: center
						gap: 1rem
						.transaction_list_header_item
							font-size: .8rem
							font-weight: 400
				.transaction_list_item
					width: 100%
					padding: 1rem
					display: grid
					grid-template-columns: repeat(4, 1fr)
					.item_wrapper
						display: flex
						align-items: center
						justify-content: center
						gap: 1rem
						.transaction_list_item_text
							font-size: .8rem
							font-weight: 400
						.transaction_list_item_button
							display: flex
							align-items: center
							justify-content: center
							background-color: $text_color_green
							border: none
							border-radius: 2rem
							padding: .6rem 1.6rem
							cursor: pointer
							transition: all .2s ease-in-out
							color: $background_color_tertiary
							&:hover
								background-color: $background_color_light
								scale: 1.1
							&:active
								background-color: $background_color_tertiary
								scale: .9
				.empty_state
					width: 100%
					min-height: 10rem
					display: flex
					align-items: center
					justify-content: center

.transaction_modal_wrapper
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	background-color: rgba(0, 0, 0, .5)
	display: flex
	align-items: center
	justify-content: center
	backdrop-filter: blur(5px)
	cursor: pointer
	z-index: 100
	.transaction_modal
		background-color: $background_color_secondary
		width: 50vw
		border-radius: 2rem
		padding: 2rem
		display: flex
		flex-direction: column
		align-items: center
		gap: 2rem
		cursor: default
		.modal_header
			width: 100%
			padding: 1rem
			display: flex
			align-items: center
			justify-content: space-between
			.modal_title
				font-size: 1.6rem
				font-weight: 500
				color: $text_color_green
			.modal_close
				display: flex
				align-items: center
				justify-content: center
				background-color: $text_color_green
				border: none
				border-radius: 2rem
				padding: .6rem 1.6rem
				cursor: pointer
				transition: all .2s ease-in-out
				color: $background_color_tertiary
				&:hover
					background-color: $background_color_light
					scale: 1.1
				&:active
					background-color: $background_color_tertiary
					scale: .9
		.modal_body
			display: flex
			flex-direction: column
			align-items: center
			justify-content: space-evenly
			width: 100%
			height: 100%
			gap: 1rem
			.modal_body_item
				width: 80%
				display: flex
				align-items: center
				justify-content: space-between
				.modal_body_item_label
					color: $text_color_green
					font-size: 1rem
					font-weight: 400
					width: 100%
					height: 100%
			.modal_body_image
				cursor: pointer
				width: 80%
				height: 10rem
				border-radius: 2rem
				background-color: $text_color_green
				transition: all .2s ease-in-out
				object-fit: cover
				margin-top: 2rem
				&:hover
					scale: 1.1
				&:active
					scale: .9
			.modal_cta
				width: 100%
				padding: 1rem
				display: flex
				gap: 1rem
				align-items: center
				justify-content: center
				button
					display: flex
					align-items: center
					justify-content: center
					background-color: $text_color_green
					border: none
					border-radius: 2rem
					padding: .6rem 1.6rem
					cursor: pointer
					transition: all .2s ease-in-out
					color: $background_color_tertiary
					&:hover
						background-color: $background_color_light
						scale: 1.1
					&:active
						background-color: $background_color_tertiary
						scale: .9

.trading_wrapper
	width: 100%
	display: flex
	flex-direction: column
	// gap: 2rem
	.trading_header
		width: 100%
		padding: 1rem
		display: flex
		align-items: center
		justify-content: space-between
		.trading_header_title
			font-size: 2rem
			font-weight: 500
			color: $text_color_green
		.trading_header_button
			padding: .6rem 2rem
			border-radius: 2rem
			background-color: transparent
			border: 2px solid $text_color_green
			color: $text_color_green
			font-size: .8rem
			font-weight: 400
			cursor: pointer
			transition: all .2s ease-in-out
			&:hover
				background-color: $text_color_green
				color: $background_color_tertiary
				scale: 1.1
			&:active
				background-color: $background_color_tertiary
				scale: .9
	.trading_body
		width: 100%
		display: flex
		flex-direction: column
		padding: 2rem
		background-color: $background_color_secondary
		border-radius: 2rem
		.mini_list_header
			width: 100%
			background-color: $background_color_tertiary
			display: grid
			grid-template-columns: repeat(5, 1fr)
			padding: 1rem
			border-radius: 1rem 1rem 0 0
			.item_wrapper
				display: flex
				align-items: center
				justify-content: center
				gap: 1rem
				.mini_list_header_item
					font-size: .8rem
					font-weight: 400
		.list_wrapper
			width: 100%
			// padding: 1rem
			border: 2px solid $background_color_tertiary
			border-radius: 0 0 1rem 1rem
		.mini_list_item
			width: 100%
			padding: 1rem
			display: grid
			grid-template-columns: repeat(5, 1fr)
			.item_wrapper
				display: flex
				align-items: center
				justify-content: center
				gap: 1rem
				.mini_list_item_text
					font-size: .8rem
					font-weight: 400
				.mini_list_item_button
					display: flex
					align-items: center
					justify-content: center
					background-color: $text_color_green
					border: none
					border-radius: 2rem
					padding: .6rem 1.6rem
					cursor: pointer
					transition: all .2s ease-in-out
					color: $background_color_tertiary
					&:hover
						background-color: $background_color_light
						scale: 1.1
					&:active
						background-color: $background_color_tertiary
						scale: .9

.trading_modal_wrapper
	width: 100vw
	height: 100vh
	position: fixed
	top: 0
	left: 0
	z-index: 100
	background-color: rgba(0, 0, 0, .5)
	backdrop-filter: blur(5px)
	cursor: pointer
	display: flex
	align-items: center
	justify-content: center
	.trading_modal
		width: 60%
		padding: 2rem
		border-radius: 2rem
		background-color: $background_color_secondary
		display: flex
		flex-direction: column
		gap: 3rem
		cursor: default
		.modal_header
			width: 100%
			display: flex
			align-items: center
			justify-content: space-between
			.modal_header_title
				font-size: 1.6rem
				color: $text_color_green
			.modal_header_button
				padding: .6rem 2rem
				border-radius: 2rem
				background-color: transparent
				border: 2px solid $text_color_green
				color: $text_color_green
				font-size: .8rem
				font-weight: 400
				cursor: pointer
				transition: all .2s ease-in-out
				&:hover
					background-color: $text_color_green
					color: $background_color_tertiary
					scale: 1.1
				&:active
					background-color: $background_color_tertiary
					scale: .9
		.modal_body
			width: 100%
			display: flex
			flex-direction: column
			gap: 2rem
			.modal_info
				width: 100%
				display: flex
				flex-direction: column
				gap: 1rem
				.info_item
					padding: 0 2rem
					width: 100%
					display: flex
					align-items: center
					justify-content: space-between
					.info_title
						font-size: 1rem
						color: $text_color_green
					.info_text
						font-size: 1rem
						color: $text_color_white
		.modal_form
			width: 100%
			display: flex
			flex-direction: column
			gap: 1rem
			padding: 0 2rem
			.row
				display: flex
				align-items: center
				justify-content: space-between
				.mini_title
					font-size: 1.2rem
					color: $text_color_white
				.switch_button_group
					display: flex
					align-items: center
					gap: 1rem
				.switch_button
					padding: .6rem 2rem
					border-radius: 2rem
					background-color: transparent
					border: 2px solid $text_color_green
					color: $text_color_green
					font-size: .8rem
					font-weight: 400
					cursor: pointer
					transition: all .2s ease-in-out
					&:hover
						background-color: $text_color_green
						color: $background_color_tertiary
						scale: 1.1
					&:active
						background-color: $background_color_tertiary
						scale: .9
				.selected
					background-color: $text_color_green
					color: $background_color_tertiary
					&:hover
						background-color: $text_color_green
						color: $background_color_tertiary
						scale: 1
					&:active
						background-color: $text_color_green
						color: $background_color_tertiary
						scale: 1
			.form_input
				padding: 1.2rem 2rem
				width: 100%
				border: none
				border: 2px solid $background_color_tertiary
				background-color: transparent
				color: $text_color_white
				font-size: 1rem
				font-weight: 400
				border-radius: 2rem
				transition: all .2s ease-in-out
				&:focus
					outline: none
					border: 2px solid $text_color_green
				&::placeholder
					color: $text_color_gray
		.modal_cta
			width: 100%
			display: flex
			align-items: center
			justify-content: center
			gap: 1rem
			.modal_cta_button
				display: flex
				align-items: center
				justify-content: center
				background-color: $text_color_green
				border: none
				border-radius: 2rem
				padding: 1rem 4rem
				cursor: pointer
				transition: all .2s ease-in-out
				color: $background_color_tertiary
				&:hover
					background-color: $background_color_light
					scale: 1.1
				&:active
					background-color: $background_color_tertiary
					scale: .9

.disabled_button
	background-color: $background_color_tertiary !important
	color: $text_color_gray !important
	cursor: not-allowed !important
	&:hover
		background-color: $background_color_tertiary !important
		color: $text_color_gray !important
		scale: 1 !important
	&:active
		background-color: $background_color_tertiary !important
		color: $text_color_gray !important
		scale: 1 !important

.wallet_address
	width: 100%
	padding: 2rem
	display: flex
	flex-direction: column
	gap: 2rem
	.wallet_container
		display: flex
		align-items: start
		flex-direction: column
		gap: 1rem
	.input_container
		width: 100%
		display: flex
		gap: 1rem
	.wallet_address_title
		font-size: 1rem
		font-weight: 500
		color: $text_color_green
	.wallet_address_input
		padding: 1.2rem 2rem
		// width: 100%
		flex: 1
		border: none
		border: 2px solid $background_color_tertiary
		background-color: transparent
		color: $text_color_white
		font-size: 1rem
		font-weight: 400
		border-radius: 2rem
		transition: all .2s ease-in-out
		&:focus
			outline: none
			border: 2px solid $text_color_green
		&::placeholder
			color: $text_color_gray
	.error
		color: $text_color_black !important
		padding: 2rem
		border-radius: 100rem
		background-color: $text_color_error !important
		position: fixed
		top: 2rem
		left: 45%
		z-index: 1001
	.success
		color: $text_color_black !important
		padding: 2rem
		border-radius: 100rem
		background-color: $text_color_success !important
		position: fixed
		top: 2rem
		left: 45%
		z-index: 1001

	.submit_button
		font-size: .8rem
		display: flex
		align-items: center
		justify-content: center
		background-color: $text_color_green
		border: none
		border-radius: 2rem
		padding: 1rem 2rem
		cursor: pointer
		transition: all .2s ease-in-out
		color: $background_color_tertiary
		width: fit-content
		&:hover
			background-color: $background_color_light
			scale: 1.1
		&:active
			background-color: $background_color_tertiary
			scale: .9

.dashboard_main
	padding: 2rem
	flex-grow: 1
	.title
		font-size: 2rem
		font-weight: 500
		color: $text_color_green
// phone styles
@media screen and (max-width: 900px)
	.dashboard_container
		width: 100vw
		height: 100vh
		padding: 2rem
		display: flex
		align-items: flex-start
		justify-content: space-between
		gap: 2rem

.search
	width: 100%
	background-color: transparent
	margin-bottom: 1rem
	padding: 1rem
	border-radius: 2rem
	border: 2px solid $background_color_tertiary
	outline: none
	transition: all .2s ease-in-out
	&:focus
		border: 2px solid $text_color_green
	&::placeholder
		color: $text_color_gray



.admins_container
	display: flex
	width: 100%
	gap: 10%
	margin-top: 2rem
.admin_list
	display: flex
	flex-direction: column
	gap: 1rem
	width: 45%
.admin_list_item_container
	display: flex
	flex-direction: column
	gap: 1rem
hr
	width: 100%
	border: 1px solid $background_color_tertiary
.admin_list_item
	display: flex
	width: 100%
	align-items: center
	justify-content: space-between
	.actions
		display: flex
		align-items: center
		justify-content: center
		gap: 1rem

.actions_section
	width: 45%
	display: flex
	flex-direction: column
	.message
		width: 100%
		height: 40vh
		display: flex
		align-items: center
		justify-content: center
	.actions_list
		display: flex
		flex-direction: column
		gap: 1rem