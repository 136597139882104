@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500&family=Space+Mono:wght@400;700&display=swap')

input[type=range]
	height: 36px
	-webkit-appearance: none
	margin: 10px 0
	width: 100%

	&:focus
		outline: none

	&::-webkit-slider-runnable-track
		width: 100%
		height: 14px
		cursor: pointer
		animate: 0.2s
		box-shadow: 0px 0px 0px #000000
		background: #414344
		border-radius: 36px
		border: 0px solid #000000

	&::-webkit-slider-thumb
		box-shadow: 0px 0px 0px #000000
		border: 1px solid #000000
		height: 29px
		width: 44px
		border-radius: 30px
		background: #77ED91
		cursor: pointer
		-webkit-appearance: none
		margin-top: -8px

	&:focus::-webkit-slider-runnable-track
		background: #414344

	&::-moz-range-track
		width: 100%
		height: 14px
		cursor: pointer
		animate: 0.2s
		box-shadow: 0px 0px 0px #000000
		background: #414344
		border-radius: 36px
		border: 0px solid #000000

	&::-moz-range-thumb
		box-shadow: 0px 0px 0px #000000
		border: 1px solid #000000
		height: 29px
		width: 44px
		border-radius: 30px
		background: #77ED91
		cursor: pointer

	&::-ms-track
		width: 100%
		height: 14px
		cursor: pointer
		animate: 0.2s
		background: transparent
		border-color: transparent
		color: transparent

	&::-ms-fill-lower, &::-ms-fill-upper
		background: #414344
		border: 0px solid #000000
		border-radius: 72px
		box-shadow: 0px 0px 0px #000000

	&::-ms-thumb
		margin-top: 1px
		box-shadow: 0px 0px 0px #000000
		border: 1px solid #000000
		height: 29px
		width: 44px
		border-radius: 30px
		background: #77ED91
		cursor: pointer

	&:focus
		&::-ms-fill-lower, &::-ms-fill-upper
			background: #414344

	&::selection
		background-color: transparent !important

.inactive_input_thimb
	&::-webkit-slider-thumb
		box-shadow: 0px 0px 0px transparent !important
		border: 1px solid transparent !important
		height: 29px
		width: 44px
		border-radius: 30px
		background: #212325 !important
		cursor: pointer
		-webkit-appearance: none
		margin-top: -8px
	&::-moz-range-thumb
		box-shadow: 0px 0px 0px transparent !important
		border: 1px solid transparent !important
		height: 29px
		width: 44px
		border-radius: 30px
		background: #212325 !important
		cursor: pointer
	&::-ms-thumb
		margin-top: 1px
		box-shadow: 0px 0px 0px transparent !important
		border: 1px solid transparent !important
		height: 29px
		width: 44px
		border-radius: 30px
		background: #212325 !important
		cursor: pointer

$font_primary: 'Poppins', sans-serif
$font_secondary: 'Space Mono', monospace

$text_color_green: #77ED91
$text_color_white: #FFFFFF
$text_color_gray: #909093
$text_color_error: rgba(249, 44, 44, 1)
$text_color_success: rgb(45, 194, 78)
$text_color_black: #0D0D0D
$background_color_primary: #0D0D0D
$background_color_secondary: #151617
$background_color_tertiary: #212325
$background_color_white: #FFFFFF
$background_color_light: #EFFFF2
$border_color_gradient: angular-gradient(90deg, #77ED91 0%, #77ED91 20%, #77ED91 40%, #77EDB4 60%, #7EFF9B 80%, #2C9543 100%)

*
	padding: 0
	margin: 0
	box-sizing: border-box
	color: $text_color_white
	&::-webkit-scrollbar
		display: none
	&::-webkit-scrollbar-track
		display: none
	&::-webkit-scrollbar-thumb
		display: none
	&::-webkit-scrollbar-thumb:hover
		display: none


body
	background-color: $background_color_primary

h1, h2, h3, h4, h5, h6, p, a, li, ul, ol, input, textarea, button, label, span, input::placeholder, textarea::placeholder
	font-family: $font_primary
	color: $text_color_white

h3
	font-weight: 500
	font-size: 1.1rem

a
	text-decoration: none

.error
	color: $text_color_error !important

.success
	color: $text_color_success !important

.green
	color: $text_color_green !important

.clickable
	cursor: pointer

@import ./styles/_Sign.sass
@import ./styles/_Dashboard.sass
@import ./styles/_SideBar.sass
@import ./styles/_Admins.sass
