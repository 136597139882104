.modal_wrapper
	width: 100vw
	height: 100vh
	position: fixed
	top: 0
	left: 0
	display: flex
	align-items: center
	justify-content: center
	background-color: rgba(0, 0, 0, 0.5)
	z-index: 100
	overflow: hidden
	backdrop-filter: blur(5px)
	.assign_users_modal
		width: 60vw
		max-height: 80vh
		padding: 1rem
		border-radius: 1rem
		background-color: $background_color_tertiary
		display: flex
		flex-direction: column
		gap: 2rem
		.assign_users_list
			width: 100%
			gap: 1rem
			overflow-y: auto
			display: grid
			grid-template-columns: repeat(3, 1fr)
			.edit_modal_form_button
				width: fit-content