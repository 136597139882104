@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500&family=Space+Mono:wght@400;700&display=swap");
input[type=range] {
  height: 36px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #414344;
  border-radius: 36px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 29px;
  width: 44px;
  border-radius: 30px;
  background: #77ED91;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #414344;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #414344;
  border-radius: 36px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 29px;
  width: 44px;
  border-radius: 30px;
  background: #77ED91;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower, input[type=range]::-ms-fill-upper {
  background: #414344;
  border: 0px solid #000000;
  border-radius: 72px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 29px;
  width: 44px;
  border-radius: 30px;
  background: #77ED91;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower, input[type=range]:focus::-ms-fill-upper {
  background: #414344;
}
input[type=range]::-moz-selection {
  background-color: transparent !important;
}
input[type=range]::selection {
  background-color: transparent !important;
}

.inactive_input_thimb::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px transparent !important;
  border: 1px solid transparent !important;
  height: 29px;
  width: 44px;
  border-radius: 30px;
  background: #212325 !important;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
.inactive_input_thimb::-moz-range-thumb {
  box-shadow: 0px 0px 0px transparent !important;
  border: 1px solid transparent !important;
  height: 29px;
  width: 44px;
  border-radius: 30px;
  background: #212325 !important;
  cursor: pointer;
}
.inactive_input_thimb::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px transparent !important;
  border: 1px solid transparent !important;
  height: 29px;
  width: 44px;
  border-radius: 30px;
  background: #212325 !important;
  cursor: pointer;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #FFFFFF;
}
*::-webkit-scrollbar {
  display: none;
}
*::-webkit-scrollbar-track {
  display: none;
}
*::-webkit-scrollbar-thumb {
  display: none;
}
*::-webkit-scrollbar-thumb:hover {
  display: none;
}

body {
  background-color: #0D0D0D;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "Poppins", sans-serif;
  color: #FFFFFF;
}

h1, h2, h3, h4, h5, h6, p, a, li, ul, ol, input, textarea, button, label, span, input::placeholder, textarea::placeholder {
  font-family: "Poppins", sans-serif;
  color: #FFFFFF;
}

h3 {
  font-weight: 500;
  font-size: 1.1rem;
}

a {
  text-decoration: none;
}

.error {
  color: rgb(249, 44, 44) !important;
}

.success {
  color: rgb(45, 194, 78) !important;
}

.green {
  color: #77ED91 !important;
}

.clickable {
  cursor: pointer;
}

.auth_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
  width: 100vw;
  padding-top: 10vh;
}
.auth_page .auth_container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.auth_page .auth_container .auth_info {
  margin-bottom: 2rem;
}
.auth_page .auth_container .auth_title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #77ED91;
  margin-bottom: 1rem;
  text-align: center;
}
.auth_page .auth_container .auth_description {
  font-size: 1.2rem;
  font-weight: 400;
  color: #909093;
  text-align: center;
}
.auth_page .auth_container .auth_description span {
  color: #77ED91;
}
.auth_page .auth_container .auth_form {
  width: 100%;
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.auth_page .auth_container .auth_form .auth_form_group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}
.auth_page .auth_container .auth_form .auth_form_group label {
  font-size: 1.2rem;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 0.5rem;
}
.auth_page .auth_container .auth_form .auth_form_group .password_input {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.auth_page .auth_container .auth_form .auth_form_group .eye_container {
  padding: 0 1rem;
}
.auth_page .auth_container .auth_form .auth_form_group input {
  width: 100%;
  padding: 1.5rem 2rem;
  border: 2px solid rgba(119, 237, 145, 0.2);
  border-radius: 10rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}
.auth_page .auth_container .auth_form .auth_form_group input:focus {
  outline: none;
  border: 2px solid rgb(119, 237, 145);
  background-color: transparent;
}
.auth_page .auth_container .auth_form .auth_form_group input::-moz-selection {
  background-color: rgba(119, 237, 145, 0.2);
}
.auth_page .auth_container .auth_form .auth_form_group input::selection {
  background-color: rgba(119, 237, 145, 0.2);
}
.auth_page .auth_container .auth_form .auth_form_group input::-moz-placeholder {
  color: rgba(119, 237, 145, 0.4);
}
.auth_page .auth_container .auth_form .auth_form_group input::placeholder {
  color: rgba(119, 237, 145, 0.4);
}
.auth_page .auth_container .auth_form .auth_form_group input:autofill::-moz-selection {
  background-color: transparent !important;
}
.auth_page .auth_container .auth_form .auth_form_group input:-webkit-autofill::selection {
  background-color: transparent !important;
}
.auth_page .auth_container .auth_form .auth_form_group input:autofill::selection {
  background-color: transparent !important;
}
.auth_page .auth_container .auth_form .auth_form_group .error {
  color: rgb(249, 44, 44);
}
.auth_page .auth_container .auth_form button {
  margin-top: 1rem;
  width: 60%;
  padding: 1.5rem 2rem;
  border: none;
  border-radius: 10rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: #77ED91;
  color: #0D0D0D;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.auth_page .auth_container .auth_form button:hover {
  background-color: #FFFFFF;
  color: #0D0D0D;
  scale: 1.1;
}
.auth_page .auth_container .auth_form button:active {
  outline: none;
  color: #0D0D0D;
  scale: 0.9;
}

.dashboard_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  background-color: #0D0D0D;
  padding-left: 20%;
  overflow: hidden;
}
.dashboard_container .section_wrapper {
  width: 50%;
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
}
.dashboard_container .users_header {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard_container .users_header .users_header_title {
  font-size: 2rem;
  font-weight: 500;
  color: #77ED91;
}
.dashboard_container .users_header .header_cta {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.dashboard_container .users_header .header_cta .users_header_button {
  padding: 0.6rem 2rem;
  border-radius: 2rem;
  background-color: transparent;
  border: 2px solid #77ED91;
  color: #77ED91;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.dashboard_container .users_header .header_cta .users_header_button:hover {
  background-color: #77ED91;
  color: #212325;
  scale: 1.1;
}
.dashboard_container .users_header .header_cta .users_header_button:active {
  background-color: #212325;
  scale: 0.9;
}
.dashboard_container .item_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.dashboard_container .users_list {
  background-color: #151617;
  padding: 2rem;
  border-radius: 2rem;
}
.dashboard_container .users_list .users_list_header {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: #212325;
  border-radius: 1rem 1rem 0 0;
}
.dashboard_container .users_list .users_list_body {
  width: 100%;
  border: 2px solid #212325;
  border-radius: 0 0 1rem 1rem;
}
.dashboard_container .users_list .users_list_body .users_list_item {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.dashboard_container .users_list .users_list_body .users_list_item .users_list_item_text {
  font-size: 0.8rem;
  font-weight: 400;
}
.dashboard_container .users_list .users_list_body .users_list_item .users_list_item_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77ED91;
  border: none;
  border-radius: 2rem;
  padding: 0.6rem 1.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #212325;
}
.dashboard_container .users_list .users_list_body .users_list_item .users_list_item_button:hover {
  background-color: #EFFFF2;
  scale: 1.1;
}
.dashboard_container .users_list .users_list_body .users_list_item .users_list_item_button:active {
  background-color: #212325;
  scale: 0.9;
}

.edit_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  cursor: pointer;
  z-index: 100;
}

.edit_modal_form {
  background-color: #151617;
  width: 50vw;
  border-radius: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  cursor: default;
}

.edit_modal_form_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  gap: 1rem;
}

.edit_modal_form_label {
  color: #77ED91;
  font-size: 0.8rem;
  font-weight: 400;
  width: 100%;
  height: 100%;
}

.edit_modal_form_input {
  padding: 1.2rem 2rem;
  width: 100%;
  height: 100%;
  border: none;
  border: 2px solid #212325;
  background-color: transparent;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 2rem;
  transition: all 0.2s ease-in-out;
}
.edit_modal_form_input:focus {
  outline: none;
  border: 2px solid #77ED91;
}

.edit_modal_form_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77ED91;
  border: none;
  border-radius: 2rem;
  padding: 0.6rem 1.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #212325;
}
.edit_modal_form_button:hover {
  background-color: #EFFFF2;
  scale: 1.1;
}
.edit_modal_form_button:active {
  background-color: #212325;
  scale: 0.9;
}

.row-full-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.transactions_list_wrapper {
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}
.transactions_list_wrapper .transactions_header {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transactions_list_wrapper .transactions_header .transactions_header_title {
  font-size: 2rem;
  font-weight: 500;
  color: #77ED91;
}
.transactions_list_wrapper .transactions_header .cta {
  display: flex;
  gap: 1rem;
  align-items: centers;
}
.transactions_list_wrapper .transactions_header .transactions_header_button {
  padding: 0.6rem 2rem;
  border-radius: 2rem;
  background-color: transparent;
  border: 2px solid #77ED91;
  color: #77ED91;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.transactions_list_wrapper .transactions_header .transactions_header_button:hover {
  background-color: #77ED91;
  color: #212325;
  scale: 1.1;
}
.transactions_list_wrapper .transactions_header .transactions_header_button:active {
  background-color: #212325;
  scale: 0.9;
}
.transactions_list_wrapper .transactions_header .selected {
  background-color: #77ED91;
  color: #212325;
}
.transactions_list_wrapper .transactions_header .selected:hover {
  background-color: #77ED91;
  color: #212325;
  scale: 1;
}
.transactions_list_wrapper .transactions_header .selected:active {
  background-color: #77ED91;
  color: #212325;
  scale: 1;
}
.transactions_list_wrapper .transaction_list_container {
  width: 100%;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .body {
  width: 100%;
  border: 2px solid #212325;
  border-radius: 0 0 1rem 1rem;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list {
  width: 100%;
  padding: 2rem;
  background-color: #151617;
  border-radius: 2rem;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #77ED91;
  margin-bottom: 1rem;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_header {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #212325;
  border-radius: 1rem 1rem 0 0;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_header .item_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_header .item_wrapper .transaction_list_header_item {
  font-size: 0.8rem;
  font-weight: 400;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_item {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_item .item_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_item .item_wrapper .transaction_list_item_text {
  font-size: 0.8rem;
  font-weight: 400;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_item .item_wrapper .transaction_list_item_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77ED91;
  border: none;
  border-radius: 2rem;
  padding: 0.6rem 1.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #212325;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_item .item_wrapper .transaction_list_item_button:hover {
  background-color: #EFFFF2;
  scale: 1.1;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .transaction_list_item .item_wrapper .transaction_list_item_button:active {
  background-color: #212325;
  scale: 0.9;
}
.transactions_list_wrapper .transaction_list_container .transaction_list_body .transaction_list .empty_state {
  width: 100%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transaction_modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  cursor: pointer;
  z-index: 100;
}
.transaction_modal_wrapper .transaction_modal {
  background-color: #151617;
  width: 50vw;
  border-radius: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  cursor: default;
}
.transaction_modal_wrapper .transaction_modal .modal_header {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transaction_modal_wrapper .transaction_modal .modal_header .modal_title {
  font-size: 1.6rem;
  font-weight: 500;
  color: #77ED91;
}
.transaction_modal_wrapper .transaction_modal .modal_header .modal_close {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77ED91;
  border: none;
  border-radius: 2rem;
  padding: 0.6rem 1.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #212325;
}
.transaction_modal_wrapper .transaction_modal .modal_header .modal_close:hover {
  background-color: #EFFFF2;
  scale: 1.1;
}
.transaction_modal_wrapper .transaction_modal .modal_header .modal_close:active {
  background-color: #212325;
  scale: 0.9;
}
.transaction_modal_wrapper .transaction_modal .modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  gap: 1rem;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_body_item {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_body_item .modal_body_item_label {
  color: #77ED91;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  height: 100%;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_body_image {
  cursor: pointer;
  width: 80%;
  height: 10rem;
  border-radius: 2rem;
  background-color: #77ED91;
  transition: all 0.2s ease-in-out;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: 2rem;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_body_image:hover {
  scale: 1.1;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_body_image:active {
  scale: 0.9;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_cta {
  width: 100%;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_cta button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77ED91;
  border: none;
  border-radius: 2rem;
  padding: 0.6rem 1.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #212325;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_cta button:hover {
  background-color: #EFFFF2;
  scale: 1.1;
}
.transaction_modal_wrapper .transaction_modal .modal_body .modal_cta button:active {
  background-color: #212325;
  scale: 0.9;
}

.trading_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.trading_wrapper .trading_header {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trading_wrapper .trading_header .trading_header_title {
  font-size: 2rem;
  font-weight: 500;
  color: #77ED91;
}
.trading_wrapper .trading_header .trading_header_button {
  padding: 0.6rem 2rem;
  border-radius: 2rem;
  background-color: transparent;
  border: 2px solid #77ED91;
  color: #77ED91;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.trading_wrapper .trading_header .trading_header_button:hover {
  background-color: #77ED91;
  color: #212325;
  scale: 1.1;
}
.trading_wrapper .trading_header .trading_header_button:active {
  background-color: #212325;
  scale: 0.9;
}
.trading_wrapper .trading_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #151617;
  border-radius: 2rem;
}
.trading_wrapper .trading_body .mini_list_header {
  width: 100%;
  background-color: #212325;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
}
.trading_wrapper .trading_body .mini_list_header .item_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.trading_wrapper .trading_body .mini_list_header .item_wrapper .mini_list_header_item {
  font-size: 0.8rem;
  font-weight: 400;
}
.trading_wrapper .trading_body .list_wrapper {
  width: 100%;
  border: 2px solid #212325;
  border-radius: 0 0 1rem 1rem;
}
.trading_wrapper .trading_body .mini_list_item {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.trading_wrapper .trading_body .mini_list_item .item_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.trading_wrapper .trading_body .mini_list_item .item_wrapper .mini_list_item_text {
  font-size: 0.8rem;
  font-weight: 400;
}
.trading_wrapper .trading_body .mini_list_item .item_wrapper .mini_list_item_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77ED91;
  border: none;
  border-radius: 2rem;
  padding: 0.6rem 1.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #212325;
}
.trading_wrapper .trading_body .mini_list_item .item_wrapper .mini_list_item_button:hover {
  background-color: #EFFFF2;
  scale: 1.1;
}
.trading_wrapper .trading_body .mini_list_item .item_wrapper .mini_list_item_button:active {
  background-color: #212325;
  scale: 0.9;
}

.trading_modal_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trading_modal_wrapper .trading_modal {
  width: 60%;
  padding: 2rem;
  border-radius: 2rem;
  background-color: #151617;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  cursor: default;
}
.trading_modal_wrapper .trading_modal .modal_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trading_modal_wrapper .trading_modal .modal_header .modal_header_title {
  font-size: 1.6rem;
  color: #77ED91;
}
.trading_modal_wrapper .trading_modal .modal_header .modal_header_button {
  padding: 0.6rem 2rem;
  border-radius: 2rem;
  background-color: transparent;
  border: 2px solid #77ED91;
  color: #77ED91;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.trading_modal_wrapper .trading_modal .modal_header .modal_header_button:hover {
  background-color: #77ED91;
  color: #212325;
  scale: 1.1;
}
.trading_modal_wrapper .trading_modal .modal_header .modal_header_button:active {
  background-color: #212325;
  scale: 0.9;
}
.trading_modal_wrapper .trading_modal .modal_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.trading_modal_wrapper .trading_modal .modal_body .modal_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.trading_modal_wrapper .trading_modal .modal_body .modal_info .info_item {
  padding: 0 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trading_modal_wrapper .trading_modal .modal_body .modal_info .info_item .info_title {
  font-size: 1rem;
  color: #77ED91;
}
.trading_modal_wrapper .trading_modal .modal_body .modal_info .info_item .info_text {
  font-size: 1rem;
  color: #FFFFFF;
}
.trading_modal_wrapper .trading_modal .modal_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 2rem;
}
.trading_modal_wrapper .trading_modal .modal_form .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trading_modal_wrapper .trading_modal .modal_form .row .mini_title {
  font-size: 1.2rem;
  color: #FFFFFF;
}
.trading_modal_wrapper .trading_modal .modal_form .row .switch_button_group {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.trading_modal_wrapper .trading_modal .modal_form .row .switch_button {
  padding: 0.6rem 2rem;
  border-radius: 2rem;
  background-color: transparent;
  border: 2px solid #77ED91;
  color: #77ED91;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.trading_modal_wrapper .trading_modal .modal_form .row .switch_button:hover {
  background-color: #77ED91;
  color: #212325;
  scale: 1.1;
}
.trading_modal_wrapper .trading_modal .modal_form .row .switch_button:active {
  background-color: #212325;
  scale: 0.9;
}
.trading_modal_wrapper .trading_modal .modal_form .row .selected {
  background-color: #77ED91;
  color: #212325;
}
.trading_modal_wrapper .trading_modal .modal_form .row .selected:hover {
  background-color: #77ED91;
  color: #212325;
  scale: 1;
}
.trading_modal_wrapper .trading_modal .modal_form .row .selected:active {
  background-color: #77ED91;
  color: #212325;
  scale: 1;
}
.trading_modal_wrapper .trading_modal .modal_form .form_input {
  padding: 1.2rem 2rem;
  width: 100%;
  border: none;
  border: 2px solid #212325;
  background-color: transparent;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 2rem;
  transition: all 0.2s ease-in-out;
}
.trading_modal_wrapper .trading_modal .modal_form .form_input:focus {
  outline: none;
  border: 2px solid #77ED91;
}
.trading_modal_wrapper .trading_modal .modal_form .form_input::-moz-placeholder {
  color: #909093;
}
.trading_modal_wrapper .trading_modal .modal_form .form_input::placeholder {
  color: #909093;
}
.trading_modal_wrapper .trading_modal .modal_cta {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.trading_modal_wrapper .trading_modal .modal_cta .modal_cta_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77ED91;
  border: none;
  border-radius: 2rem;
  padding: 1rem 4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #212325;
}
.trading_modal_wrapper .trading_modal .modal_cta .modal_cta_button:hover {
  background-color: #EFFFF2;
  scale: 1.1;
}
.trading_modal_wrapper .trading_modal .modal_cta .modal_cta_button:active {
  background-color: #212325;
  scale: 0.9;
}

.disabled_button {
  background-color: #212325 !important;
  color: #909093 !important;
  cursor: not-allowed !important;
}
.disabled_button:hover {
  background-color: #212325 !important;
  color: #909093 !important;
  scale: 1 !important;
}
.disabled_button:active {
  background-color: #212325 !important;
  color: #909093 !important;
  scale: 1 !important;
}

.wallet_address {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.wallet_address .wallet_container {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1rem;
}
.wallet_address .input_container {
  width: 100%;
  display: flex;
  gap: 1rem;
}
.wallet_address .wallet_address_title {
  font-size: 1rem;
  font-weight: 500;
  color: #77ED91;
}
.wallet_address .wallet_address_input {
  padding: 1.2rem 2rem;
  flex: 1;
  border: none;
  border: 2px solid #212325;
  background-color: transparent;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 2rem;
  transition: all 0.2s ease-in-out;
}
.wallet_address .wallet_address_input:focus {
  outline: none;
  border: 2px solid #77ED91;
}
.wallet_address .wallet_address_input::-moz-placeholder {
  color: #909093;
}
.wallet_address .wallet_address_input::placeholder {
  color: #909093;
}
.wallet_address .error {
  color: #0D0D0D !important;
  padding: 2rem;
  border-radius: 100rem;
  background-color: rgb(249, 44, 44) !important;
  position: fixed;
  top: 2rem;
  left: 45%;
  z-index: 1001;
}
.wallet_address .success {
  color: #0D0D0D !important;
  padding: 2rem;
  border-radius: 100rem;
  background-color: rgb(45, 194, 78) !important;
  position: fixed;
  top: 2rem;
  left: 45%;
  z-index: 1001;
}
.wallet_address .submit_button {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77ED91;
  border: none;
  border-radius: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #212325;
  width: -moz-fit-content;
  width: fit-content;
}
.wallet_address .submit_button:hover {
  background-color: #EFFFF2;
  scale: 1.1;
}
.wallet_address .submit_button:active {
  background-color: #212325;
  scale: 0.9;
}

.dashboard_main {
  padding: 2rem;
  flex-grow: 1;
}
.dashboard_main .title {
  font-size: 2rem;
  font-weight: 500;
  color: #77ED91;
}

@media screen and (max-width: 900px) {
  .dashboard_container {
    width: 100vw;
    height: 100vh;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
  }
}
.search {
  width: 100%;
  background-color: transparent;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 2rem;
  border: 2px solid #212325;
  outline: none;
  transition: all 0.2s ease-in-out;
}
.search:focus {
  border: 2px solid #77ED91;
}
.search::-moz-placeholder {
  color: #909093;
}
.search::placeholder {
  color: #909093;
}

.admins_container {
  display: flex;
  width: 100%;
  gap: 10%;
  margin-top: 2rem;
}

.admin_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 45%;
}

.admin_list_item_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

hr {
  width: 100%;
  border: 1px solid #212325;
}

.admin_list_item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.admin_list_item .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.actions_section {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.actions_section .message {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actions_section .actions_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dashboard_sidebar_container {
  width: 20%;
  height: 100vh;
  background-color: #151617;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  position: fixed;
  left: 0;
  padding: 2rem 0;
}
.dashboard_sidebar_container .top, .dashboard_sidebar_container .bottom {
  width: 100%;
  padding: 2rem;
}
.dashboard_sidebar_container .logo_section {
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard_sidebar_container .logo_section .logo {
  height: 2.4rem;
}
.dashboard_sidebar_container .dashboard_list_item {
  color: #FFFFFF;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  border-radius: 4rem;
  margin-bottom: 1rem;
  cursor: pointer;
  border: 3px solid transparent;
  transition: all 0.2s ease-in-out;
  gap: 1rem;
}
.dashboard_sidebar_container .dashboard_list_item * {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.dashboard_sidebar_container .dashboard_list_item .dashboard_list_item_icon {
  display: inline-block;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.dashboard_sidebar_container .dashboard_list_item .dashboard_list_item_icon .dashboard_list_item_icon_src {
  width: 1.5rem;
  height: auto;
}
.dashboard_sidebar_container .dashboard_list_item .dashboard_list_item_title {
  font-size: 1.2rem;
  font-weight: 300;
  color: #FFFFFF;
}
.dashboard_sidebar_container .dashboard_list_item:hover {
  background-color: #212325;
}
.dashboard_sidebar_container .dashboard_list_item.selected {
  color: #0D0D0D;
  border: 3px solid #77ED91;
  background-color: #FFFFFF;
}
.dashboard_sidebar_container .dashboard_list_item.selected .dashboard_list_item_icon {
  background-color: #0D0D0D;
}
.dashboard_sidebar_container .dashboard_list_item.selected .dashboard_list_item_text {
  color: #77ED91;
}
.dashboard_sidebar_container .dashboard_list_item.selected .dashboard_list_item_icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #FFFFFF;
}
.dashboard_sidebar_container .dashboard_list_item.selected .dashboard_list_item_title {
  font-weight: 500;
  font-size: 1.2rem;
  color: #0D0D0D;
}
.dashboard_sidebar_container .dashboard_list_item.selected .dashboard_list_item_title.active {
  color: #77ED91;
}

.modal_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: hidden;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.modal_wrapper .assign_users_modal {
  width: 60vw;
  max-height: 80vh;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #212325;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.modal_wrapper .assign_users_modal .assign_users_list {
  width: 100%;
  gap: 1rem;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.modal_wrapper .assign_users_modal .assign_users_list .edit_modal_form_button {
  width: -moz-fit-content;
  width: fit-content;
}/*# sourceMappingURL=index.css.map */